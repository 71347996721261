<template>
  <v-container>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <h1>Categoria</h1>
        <v-form @submit.prevent="submit()">
          <v-text-field
            v-model="categoria.nome"
            label="Nome"
            name="nome"
            type="text"
            required
            :rules="rules"
          />

          <v-btn
            color="primary"
            type="submit"
          >
            Salvar
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    data: () => ({
      categoria: {},
      rules: [
        v => !!v || 'Esse campo é obrigatório',
      ],
    }),
    mounted () {
      if (this.$route.params.id) {
        this.load()
      }
    },
    methods: {
      load () {
        this.$http.get(`/ead/categorias/${this.$route.params.id}`)
          .then(resp => {
            this.categoria = resp.data
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      submit () {
        if (!this.categoria.nome) {
          return
        }
        if (this.$route.params.id) {
          this.put()
        } else {
          this.post()
        }
      },
      post () {
        this.$http.post('/ead/categorias/', this.categoria)
          .then(resp => {
            this.$toast.success('Operação realizada com sucesso')
            this.$router.push('/pages/ead/categorias')
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      put () {
        this.$http.put('/ead/categorias/', this.categoria)
          .then(resp => {
            this.$toast.success('Operação realizada com sucesso')
            this.$router.push('/pages/ead/categorias')
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
    },
  }
</script>
